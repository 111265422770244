.home-component{
    .plan-box {
        position: relative;
        padding: 14px 13px 13px;
        border-radius: 10px;
        border: 1px solid #fff;
        margin-bottom: 10%;
        background-color: #ffffff;
        height: 423px;
    }



    .plan-header {
        position: relative;
        width: 100%;
        padding: 10px 18px 17px;
        top: 6px;

    }

    .plan-header h3 {
        font-size: 22px;
        color: #3c4068;
        text-transform: capitalize;
        font-weight: 500;
    }


    .plan-header i {
        font-size: 22px;
        color: #c46482;
        text-align: right;
        position: absolute;
        right: 21px;
        top: 18px;
        font-size: 29px;
    }


    .plan-body {
        padding: 0px 11px 10px;
        position: relative;
        text-align: center;

    }


    .plan-price span {
        font-size: 29px;
        font-weight: 400;
    }

    .plan-price {
        border-bottom: 1px solid #ccc;
        padding-left: 0px;
    }

    .plan-icon i {
        color: #0973d6;;
        
    }

    .plan-body ul li {
        list-style: none;
        padding: 12px;
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 400;
        text-align: left;
    }


    .plan-description ul {
        padding-left: 0px;
    }


    .plan-btn {
        padding: 10px 10px 10px;
        background-color: #feb74d;
        text-align: center;
        font-size: 22px;
        text-align: center;
        color: #41392f !important;
        border-radius: 32px;
        width: 57%;
        font-weight: 500;
        text-decoration: none;
    }

    .plan-btn:hover {

        background-color: #feb74b;
    }

    .plan-body h3 {
        font-weight: 400;
        text-transform: capitalize;
    }


    .health-box {
        position: relative;
        padding: 14px 23px;
        border-radius: 10px;
        border: 1px solid #fff;
        margin-bottom: 10%;
        background-color: #ffffff;

    }


    .health-icon {
        text-align: left;
        font-size: 25px;
    }


    .health-description h4 {
        font-size: 21px;
        font-weight: 500;
        color: #3c4068;
    }


    .health-icon i {
        color: #166ed7;
    }



    .plan-header h4 {
        font-size: 18px;
        font-weight: 400;
    }

    .plan-body ul li i {
        color: #cbdfde;
        font-size: 22px;
    }


    .book-top-btn .plan-btn {}

    .plan-button-1 a {
        color: #41392f;
        text-decoration: none;
    }





    .plan-body p {
        text-align: left;
    }


    .book-top-heading h3 {
        color: #293a5b;
    }



    .plan-card h3 {
        padding-top: 5px;
        padding-bottom: 22px;
    }

    .plan-button {
        padding: 10px 10px 10px;
        background-color: #feb74d;
        text-align: center;
        font-size: 22px;
        text-align: center;
        color: #41392f;
        border-radius: 32px;
        width: 57%;
        font-weight: 500;
        margin: 0 auto;
    }



    .plan-button-1 {

        background-color: #b0deed;
    }





    @media (max-width: 767px) {
        .book-top-btn .plan-btn {
            width: 100%;
            margin-top: 18%;

        }

        .book-top-heading {
            margin-top: 18%;
        }


        .plan-button {
            margin-top: 3px;
        }

        .plan-box {
            height: 510px;

        }
    }


    @media (max-width: 660px) {
        .plan-header i {
            right: -3px;
        }

        @media (max-width: 1024px) {
            .plan-box {
                height: 535px;
            }

        }
    }



}