.insurance {
    background: white;
    height: 100vh;

    .css-qs8l1l {
        padding: 0.75rem 20px 0 20px;
    }

    .columns.is-multiline {
        flex-wrap: wrap;
    }

    .columns.is-centered {
        justify-content: center;
    }

    .columns:last-child {
        margin-bottom: -.75rem;
    }

    .columns {
        margin-left: -.75rem;
        margin-right: -.75rem;
        margin-top: -.75rem;
    }

    .css-1bogmwi {
        margin: -5px auto 5px;
    }

    article,
    aside,
    figure,
    footer,
    header,
    hgroup,
    section {
        display: block;
    }

    .css-xz1kas {
        max-width: 100%;
        margin: 0px auto;
    }

    .css-1ruqnf0 {
        font-size: 24px;
        color: rgb(40, 55, 121);
        font-weight: 600;
    }

    .css-xz1kas .ei1rvit0 {
        margin: 30px 0px 10px;
    }

    .css-bq7cnj {
        font-size: 21px;
        color: rgb(40, 55, 121);
    }

    .columns.is-gapless:last-child {
        margin-bottom: 0;
    }

    .columns.is-vcentered {
        align-items: center;
    }

    .columns.is-gapless {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }

    .columns.is-mobile>.column.is-one-third {
        flex: none;
        width: 33.3333%;
    }

    .columns.is-gapless>.column {
        margin: 0;
        padding: 0 !important;
    }

    .column {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding: .75rem;
    }

    .css-xd0j1g {
        opacity: 1;
        outline: none 0px;
        border: none;
        cursor: pointer;
    }

    a {
        color: #485fc7;
        cursor: pointer;
        text-decoration: none;
    }

    .css-1i576z1 {
        margin: 12px 0px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        color: rgb(0, 117, 228);
    }

    .css-1yft4at {
        margin: 0px 10px 0px 0px;
    }

    img {
        height: auto;
        max-width: 100%;
    }

    img,
    svg {
        vertical-align: middle;
    }

    .columns.is-gapless>.column {
        margin: 0;
        padding: 0 !important;
    }

    .css-t2ye36 {
        color: rgb(0, 117, 228);
        cursor: pointer;
    }

    .css-rcr16b-container {
        position: relative;
        box-sizing: border-box;
    }

    .css-7pg0cj-a11yText {
        z-index: 9999;
        border: 0px;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        position: absolute;
        overflow: hidden;
        padding: 0px;
        white-space: nowrap;
    }

    .css-rcr16b-container .react-async-select__control {
        appearance: none;
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
        color: rgb(40, 55, 121);
        border: 1px solid rgb(178, 221, 237);
        border-radius: 8px;
        padding: 0px 6px 0px 0px;
        box-shadow: 0px 0px 0px 0px;
    }


    .css-13cymwt-control {
        -webkit-box-align: center;
        align-items: center;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        position: relative;
        transition: all 100ms ease 0s;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        outline: 0px !important;
    }

    .css-hlgwow {
        -webkit-box-align: center;
        align-items: center;
        display: grid;
        flex: 1 1 0%;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        padding: 2px 8px;
        box-sizing: border-box;
    }

    .css-1wy0on6 {
        -webkit-box-align: center;
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
    }

    .css-xz1kas .e1v9kpko8 {
        max-width: 200px;
        margin: 30px auto auto;
    }


    .css-1fttcpj {
        display: flex;
        flex-direction: column;
    }

    .css-rcr16b-container .react-async-select__placeholder {
        color: rgb(99, 121, 143);
    }

    .css-jgn4uk:disabled,
    .css-jgn4uk:disabled:hover,
    .css-jgn4uk:disabled:hover::before,
    .css-jgn4uk.is-disabled,
    .css-jgn4uk.is-disabled:hover,
    .css-jgn4uk.is-disabled:hover::before {
        box-shadow: none;
        background: rgb(179, 187, 189);
        color: rgb(255, 255, 255);
        transition: none 0s ease 0s;
        cursor: default;
    }

    .css-jgn4uk::before {
        box-shadow: rgb(178, 221, 237) 0px 0px 0.01px inset;
    }

    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    .css-jgn4uk {
        position: relative;
        z-index: 1;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border: 0px;
        border-radius: 27px;
        font-weight: 600;

        letter-spacing: 0.25px;
        text-decoration: none;

    }

    @media (min-width: 600px) {

        .css-s4dwsg {
            font-size: 20px;
            line-height: 1;
        }
    }

    .css-s4dwsg {
        /* position: relative; */
        /* z-index: 1; */
        /* display: flex; */
        /* -webkit-box-pack: center; */
        /* justify-content: center; */
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border-radius: 27px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.25px;
        text-decoration: none;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
        border: 1px solid rgb(255, 183, 76);
        background: rgb(255, 255, 255);
        color: rgb(40, 55, 121);
    }

    .css-jgn4uk::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27px;
        background: transparent;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
    }

    .css-1jqq78o-placeholder {
        grid-area: 1 / 1 / 2 / 3;

        margin-left: 2px;
        margin-right: 2px;
        box-sizing: border-box;
    }

    .css-19bb58m {
        visibility: visible;
        flex: 1 1 auto;
        display: inline-grid;
        grid-area: 1 / 1 / 2 / 3;
        grid-template-columns: 0px min-content;
        margin: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        color: rgb(51, 51, 51);
        box-sizing: border-box;
    }

    span {
        font-style: inherit;
        font-weight: inherit;
    }

    @media print,
    screen and (min-width: 768px) {
        .columns:not(.is-desktop) {
            display: flex;
        }

        .css-9tx5ga {
            display: none;
        }

    }

    @media screen and (min-width: 1408px) {
        .column.is-5-fullhd {
            flex: none;
            width: 41.66666674%;
        }
    }



}