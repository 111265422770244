body{
    height: 90vh;
    background: #f4f5f5;

}
.main-form {
    margin: 10px auto;
    max-width: 900px;
    min-width: 320px;
    width: 100%;
    background: #f4f5f5;
    height: 100vh;

    .main-form-box {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .3);
        padding: 30px;
    }

    .flexbox {
        display: flex;
    }

    .header .header-title {
        color: #294753;
        font-size: 2.5em;
        font-family: lato-light-italic;
        // padding-right: 30px;
        text-align: right;

    }

    .font-bold {
        font-family: lato-bold;
    }

    .font-bold,
    .font-italic {
        font-style: 14px !important;
    }

    .font-bold,
    .font-italic {
        font-style: 14px !important;
    }

    .font-italic {
        font-family: lato-italic;
    }
    .title-container>span {
        font-size: 14px;
      }
   

    .color-grey,
    .color-grey:active,
    .color-grey:focus,
    .color-grey:hover,
    .color-grey:link,
    .color-grey:visited {
        color: #9daab0;
    }

    .page-title-text {
        font-size: 18px;
        font-weight: 600;
    }

    .patient-type-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .patient-type-row .patient-type-col {
        padding: 0;
        flex: 0 0 49%;
        max-width: 49%;
        cursor: pointer;
    }

    .patient-type-row .patient-type-container {
        border: 2px solid #f26b2e;
        border-radius: 5px;
        padding: 10px;
        height: 100%;
        justify-content: center;
    }

    .add {
        width: 50px;
    }
    .patient-img{
        width: 50px;
    }
    .color-orange,
    .color-orange:active,
    .color-orange:focus,
    .color-orange:hover,
    .color-orange:link,
    .color-orange:visited {
        color: #f26b2e;
        font-weight: 600;
    }

    .section {
        display: flex;
        align-items: center;
        margin: 20px 0 10px;
    }

    .section .section-name {
        color: #00b5cc;
        font-family: lato-bold;
        font-size: 19px;
        margin-right: 10px;
        max-width: 92%;
        word-wrap: break-word;
    }

    .section .section-divider {
        height: 1px;
        background-image: repeating-linear-gradient(90deg, #e8eaeb, #e8eaeb);
        flex-grow: 1;
    }

    .fill-tag {
        font-size: 16px;
        font-weight: 600;
    }

    .appt-details-main .input-label {
        padding: 10px 0;
    }

    .appt-details-main {
        padding: 0;
    }

    .appt-details-main .input-label .label-icon {
        width: 30px;
        height: 30px;
    }

    .app-details {
        padding-left: 14px;
    }

    .label-text {
        margin-left: 7px;
    }

    .phreesia-search-input-container {
        width: 275px;
    }

    .btn:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    .btn.background-dark-blue {
        background-color: #294753;
    }

    .btn.background-dark-blue,
    .btn.background-orange {
        font-size: 14px !important;
        white-space: nowrap;
        font-family: lato-bold;
        transition: none;
    }

    .btn.color-white {
        color: #fff;
    }

    .background-white {
        background: #fff;
    }


    .border-oval-orange {
        border: 1px solid #f26b2e;
        border-radius: 16px;
    }
    // .calender-display {
    //     z-index: 1080;
    // }
    .calender-display > div {
        margin: 0 0 auto auto;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: block;
        position: absolute;
        will-change: transform;
        top: 50px;
        left: 0px;
        // transform: translate3d(1204px, 384px, 0px);
    }
    .MuiTab-labelIcon{
        background-color: #f26b2e;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
        color: #000;
        font-weight: 600;
    }
    .next-row .clear{
        width: "32%";
    }
    // 
    .row.next-row {
        margin-top: 15px;
    }
    .dashed-divider {
        height: 1px;
        background-image: repeating-linear-gradient(90deg,#d7e1e3,#d7e1e3 50%,transparent 0,transparent);
        background-position: 0 0,0 100%,0 0,100% 0;
        background-repeat: repeat-x,repeat-x,repeat-y,repeat-y;
        background-size: 20px 3px,20px 3px,3px 20px,3px 20px;
    }
   
    @media only screen and (max-width: 600px) {
        .header .header-title {
            font-size: 1.8em;
        }
        .color-orange,
        .color-orange:active,
        .color-orange:focus,
        .color-orange:hover,
        .color-orange:link,
        .color-orange:visited {
            font-size: 15px;
        }
        .page-title-text{
            font-size: 16px;
        }
        .patient-type-row{
            margin-top: 10px;
        }
        .patient-img{
            width: 40px;
        }
        .returing{
            margin-left: 10px;
        }
         .main-form-box{
            padding: 10px;
         }
         .phreesia-search-input-container{
            width: 100%;
         }
       
        .calender-display {
            margin: 0 0 auto auto;
            background-color: white;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            display: block;
            position: absolute;
            will-change: transform;
            top: 627px;
            left: 77px;
            
        }
    }
    @media only screen and (min-width: 768px) {
        .title-container {
            margin: 20px 0;
        }

        .title-container .page-title-text {
            font-size: 20px;
        }

        .patient-type-row .patient-type-container {
            padding: 20px;
        }

        .patient-type-row .patient-type-text {
            font-size: 23px;
            padding-left: 20px;
        }

        .flex-row {
            margin-left: -15px;
            margin-right: -15px;
        }
        .calender-display {
            margin: 0 0 auto auto;
            background-color: white;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            display: block;
            position: absolute;
            will-change: transform;
            top: 627px;
            left: 77px;
            transform: translate3d(1204px, -234px, 0px);
        }
        
    }
    // form css
    .category-name {
        color: #00b5cc;
        font-size: 18px;
        font-family: lato-bold;
        // padding: 0 15px;
    }
    .no-padding {
        padding: 0;
    }
    .radio-label {
        margin-left: 0.5rem;
    }
    .form-control, .input-group-addon, .text-input input[type=text] {
        border-radius: 3px;
        border: 1px solid #879ea8;
        padding: 6px 7px;
    }
    .radio label:after {
        color: red;
        content: "";
        display: inline;
    }
    .no-email label:after {
        color: red;
        content: "";
        display: inline;
    }
     label:after {
        color: red;
        content: "*";
        display: inline;
    }
    .input-label label:after {
        color: red;
        content: "";
        display: inline;
    }
     label {
        font-weight: 900;
        color: #294753;
        font-family: "lato-light";
        margin-bottom: 0.3rem;
    }
    .radio-container {
        padding-right: 15px;
        float: left;
        color: #294753;
    }
     .pseudo-drop-down{
        position: relative;
    }
    .no-email{
        margin-top: 2.1rem;
    }
    .label-font{
        margin-left: 5%;
    }
    select.placeholder{
        background-color: none !important;
    }
    .separator{
        margin-left: auto;
    }
    .btn.background-orange {
        background-color: #f26b2e;
    }
    .btn.wide-md, .btn.wide-sm, .btn.wide-xl {
        width: 100%;
        font-size: 1em!important;
        padding: 5px 10px;
        line-height: 1.5;
        border-radius: 3px;
    }
    .btn:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    
   
    .submit-form-btn[_ngcontent-fvg-c75] {
        height: 40px;
        font-size: 14px!important;
    }
    @media only screen and (min-width: 1200px){
        .wide-md {
            max-width: 260px!important;
        }
    }


}