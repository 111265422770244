.who-are-the-doc{
    // top banner
    .inside-banner {
        height: 400px;
        margin-bottom: 100px;
    }
    .top-banner {
        height: 528px;
        background-image: url("../../assets/img/top-header-bg.jpg");
        background-repeat: no-repeat;
        background-color: #f4f9f5;
        background-position: top center;
        position: relative;
        margin-bottom: 25px;
    }
    .inside-banner.top-banner .tb-content {
        width: 38%;
    }
    .top-banner .tb-content {
        left: auto;
        /* right: 15%; */
        padding: 20px;
        /* background: rgba(255,255,255,.3); */
    }
    .tb-content, .price-bnr-cntnt, .doc-bnr-cntnt, .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        /* left: 15%; */
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .inside-banner h2 {
        font-size: 38px;
        font-weight: 700;
    }
    
    .tb-content h2 {
        color: #2b3990;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    // quality care
    .inside-container {
        margin-top: 100px;
    }
    .inside-container.ins-cont-lft .row, .inside-container.ins-cont-rt .row {
        display: block;
    }
    .ins-cont-lft .ins-cont-img {
        float: right;
    }
    .ins-cont-img img {
        width: 100%;
    }
    .ins-cont-lft .ins-cont-txt {
        padding-right: 20px;
        float: left;
    }
    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }
    .inside-container h3 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 20px;
        color: #2b3990;
    }

    .inside-container p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .inside-container {
        margin-bottom: 100px;
    }
    // Virtual Psychiatric Care
    .preventative-care {
        padding: 50px;
        margin-bottom: 100px;
    }
    
    .grey-container {
        background: #e5e6ec54;
    }
    .preventative-care .inside-container {
        margin-bottom: 0;
    }
    .inside-center {
        text-align: center;
    }
    .inside-container h4 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 20px;
    }
     // app download banner
     .app-bx {
        position: relative;
    }
    .app-banner {
        height: 477px;
        background: url("../../assets/img/app-bg.jpg") top right no-repeat #f2f2f2;
    }
    .tb-content, .price-bnr-cntnt, .doc-bnr-cntnt, .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        /* left: 15%; */
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .app-bnr-cntnt h1 {
        color: #2b3990;
        font-size: 44px;
    }
    .app-bnr-cntnt h4 {
        color: #181317;
        font-size: 44px;
        /* font-weight: 300; */
        margin-bottom: 30px;
    }
    .app-bnr-cntnt p {
        font-size: 22px;
        font-weight: 300;
        margin-bottom: 30px;
    }
    .app-download-link a {
        display: inline-block;
        margin-right: 10px;
    }
    // ipad
    @media only screen and (max-width: 768px) {

    }
    // mobiles
    @media only screen and (max-width: 480px) {
        // top banner
        .top-banner {
            background-image: url("../../assets/img/top-header-bg-mob.jpg");
        }
        .inside-banner.top-banner .tb-content {
            width: 90%;
        }
        .tb-content h2 {
            font-size: 32px;
        }
        .top-banner .tb-content {
            text-align: center;
            bottom: 10px;
            position: absolute;
            background: rgba(255,255,255,.8);
            right: 0;
            left: 0;
            margin: auto;
            // width: 95%;
            padding: 5px 15px 15px;
        }
        
        .tb-content, .price-bnr-cntnt, .doc-bnr-cntnt, .app-bnr-cntnt {
            position: inherit;
            top: auto;
            left: auto;
            -ms-transform: none;
            transform: none;
        }
        .app-banner {
            height: 432px;
            background: url("../../assets/img/app-bg-mob.jpg") top right no-repeat #f2f2f2;
            margin-bottom: 30px;
        }
        .tb-content, .price-bnr-cntnt, .doc-bnr-cntnt, .app-bnr-cntnt {
            position: inherit;
            top: auto;
            left: auto;
            -ms-transform: none;
            transform: none;
        }

        
        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }

        .price-bnr-cntnt h1,
        .doc-bnr-cntnt h1,
        .app-bnr-cntnt h1 {
            font-size: 34px;
            font-weight: 600;
        }

        .app-bnr-cntnt h1 {
            text-align: center;
        }

        .app-bnr-cntnt h4 {
            font-size: 24px;
            text-align: center;
        }
        .app-download-link a {
            display: block;
            margin-bottom: 20px;
        }

    }
}