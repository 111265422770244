.members-dropdown{
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 35px;
    padding: 10px;
    justify-content: space-between;
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        border-radius: 25px !important;
    }
    .location-select{
        margin-right: 10px;
    }
    .today-select{
        margin-left: 10px;
    }
    .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
        color: #00c9fb !important;
    }
}