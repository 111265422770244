.booking-steps {
    .css-s4dwsg:hover {
        text-decoration: none;
        cursor: pointer;
    }

    .css-jgn4uk:hover {
        color: rgb(40, 55, 121);
        transform: scale(1);
    }

    .columns.is-multiline {
        flex-wrap: wrap;
    }

    .columns.is-centered {
        justify-content: center;
    }

    .columns:last-child {
        margin-bottom: -.75rem;
    }

    .columns {
        margin-left: -.75rem;
        margin-right: -.75rem;
        margin-top: -.75rem;
    }

    .css-1bogmwi {
        margin: -5px auto 5px;
    }

    article,
    aside,
    figure,
    footer,
    header,
    hgroup,
    section {
        display: block;
    }

    .css-639bzm {
        max-width: 100%;
        margin: 0px auto;
    }

    .columns.is-gapless:last-child {
        margin-bottom: 0;
    }

    .columns.is-vcentered {
        align-items: center;
    }

    .columns.is-mobile>.column.is-one-third {
        flex: none;
        width: 33.3333%;
    }

    .columns.is-gapless>.column {
        margin: 0;
        padding: 0 !important;
    }

    .css-1i576z1 {
        margin: 12px 0px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        color: rgb(0, 117, 228);
    }

    .css-1ruqnf0 {
        font-size: 24px;
        color: rgb(40, 55, 121);
        font-weight: 600;
    }

    .css-639bzm .e18q9v4c1 {
        margin-top: 60px;
    }

    .css-jgn4uk {
        position: relative;
        z-index: 1;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border: 0px;
        border-radius: 27px;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        letter-spacing: 0.25px;
        text-decoration: none;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
        background: rgb(255, 183, 76);
        color: rgb(40, 55, 121);
    }

    .css-jgn4uk::before {
        box-shadow: rgb(178, 221, 237) 0px 0px 0.01px inset;
    }

    .css-jgn4uk::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27px;
        background: transparent;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
    }

    .css-s4dwsg {
        position: relative;
        z-index: 1;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border-radius: 27px;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        letter-spacing: 0.25px;
        text-decoration: none;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
        border: 1px solid rgb(255, 183, 76);
        background: rgb(255, 255, 255);
        color: rgb(40, 55, 121);
    }

    .css-s4dwsg::before {
        box-shadow: rgb(255, 183, 76) 0px 0px 0.01px inset;
    }

    .css-s4dwsg::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27px;
        background: transparent;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    #pay .css-s4dwsg:hover {
        color: rgb(40, 55, 121);
        transform: scale(1);
    }


    @media (min-width: 600px) {
        .css-jgn4uk {
            font-size: 20px;
            line-height: 1;
        }
    }



    .css-1fttcpj {
        display: flex;
        flex-direction: column;
    }

    @media print,
    screen and (min-width: 768px) {
        .columns:not(.is-desktop) {
            display: flex;
        }
    }

    @media screen and (min-width: 1408px) {

        .column.is-5-fullhd {
            flex: none;
            width: 41.66666674%;
        }
    }

}