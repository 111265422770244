.home-container {
    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .carousel {
        position: relative;
    }

    .top-banner {
        height: 528px;
        background-image: url("../../assets/img/top-header-bg.jpg");
        background-repeat: no-repeat;
        background-color: #f4f9f5;
        background-position: top center;
        position: relative;
        margin-bottom: 25px;
    }

    .slide2 .top-banner {
        background-image: url("../../assets/img/top-header-bg2.jpg");
        background-color: #ece4e2;
    }

    .slide3 .top-banner {
        background-image: url("../../assets/img/top-header-bg3.jpg");
        background-color: #f1f0ec;
    }

    .small-banner {
        height: 125px;
        background: url("../../assets/img/inside-page-bg.jpg") top right no-repeat #f2f2f2;
        background-size: cover;
    }


    .tb-content h1 {
        color: #3e4562;
        font-size: 50px;
        font-weight: 700;
        line-height: 62px;
        margin-bottom: 30px;
        text-align: left;
        
    }

   .top-banner .tb-content {
        text-align: left;
        // margin-left: 4%;
        
       
    }
    .price-bnr-cntnt p {
        font-size: 22px;
        font-weight: 400;
    }
    .rate .rate-head {
        font-weight: 400;
        color:#3f4040;
    }

    .tb-content,
    .price-bnr-cntnt,
    .doc-bnr-cntnt,
    .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        /* left: 15%; */
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .all-tabs {
        margin-bottom: 100px;
        display: flex;
    }

    .all-tabs .col {
        text-align: center;
        padding: 5px;
        color: #fff;
    }

    .all-tabs .col .patient-tab {
        background: #2b3990 !important;
    }

    .all-tabs .col>div {
        padding: 20px 5px;
    }

    .all-tabs .col h3 {
        font-size: 26px;
        margin: 25px 0 10px;
        font-weight: 500;
    }

    .all-tabs .col p {
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .all-tabs .col .button {
        display: block;
        margin: 10px 20px;
    }

    .button.white-btn,
    .button.white-bdr-btn {
        background: #fff;
        color: #3d4461;
        padding: 8px 0;
        font-size: 18px;
    }

    .all-tabs .col .button.white-bdr-btn {
        margin-bottom: 0;
    }

    .all-tabs .col .button {
        display: block;
        margin: 10px 20px;
    }

    .button.white-bdr-btn {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
    }

    .button.white-btn,
    .button.white-bdr-btn {
        background: #fff;
        color: #3d4461;
        padding: 8px 0;
        font-size: 18px;
    }

    //doc
    .all-tabs .col .doc-tab {
        background: #00c9fb;
    }

    .all-tabs .col>div {
        padding: 20px 5px;
    }

    .all-tabs .col h3 {
        font-size: 26px;
        margin: 25px 0 10px;
        font-weight: 500;
    }

    .all-tabs .col p {
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .all-tabs .col .button {
        display: block;
        margin: 10px 20px;
    }

    .button.white-btn,
    .button.white-bdr-btn {
        background: #fff;
        color: #3d4461;
        padding: 8px 0;
        font-size: 18px;
    }

    .all-tabs .col .button.white-bdr-btn {
        margin-bottom: 0;
    }

    .all-tabs .col .button {
        display: block;
        margin: 10px 20px;
        text-decoration: none;
    }

    .button.white-bdr-btn {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
    }

    .button.white-btn,
    .button.white-bdr-btn {
        background: #fff;
        color: #3d4461;
        padding: 8px 0;
        font-size: 18px;
    }

    .button {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 10px 25px;
        color: #fff;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
    }

    .doc-tab .white-bdr-btn {
        background: #00c9fb !important;
        text-decoration: none;
        color: #fff;

    }

    .patient-tab .white-bdr-btn {
        background-color: #2b3990 !important;
        color: #fff;
    }

    .facilities-tab .white-bdr-btn {
        background-color: #0476D0 !important;
        color: #fff;
    }

    .busines-tab .white-bdr-btn {
        background: #00c9fb !important;
        text-decoration: none;
        color: #fff;

    }

    .all-tabs .col .facilities-tab {
        background: #0476D0;
    }

    .all-tabs .col>div {
        padding: 20px 5px;
    }

    .all-tabs .col .busines-tab {
        background: #03c0ef;
        margin-right: 0;
    }

    .price-banner-bx {
        position: relative;
    }

    .price-banner {
        height: 610px;
        background: url("../../assets/img/price-bg.jpg") top right no-repeat #f7fee9;
        margin-bottom: 30px;

    }

    .price-bnr-cntnt h1 {
        font-size: 44px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .tb-content,
    .price-bnr-cntnt,
    .doc-bnr-cntnt,
    .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        /* left: 15%; */
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .wthot-insu {
        background: #03c0ef;
        padding: 0 25px 20px;
        text-align: center;
        margin-top: 20px;
    }

    .wthot-insu h3 {
        margin: 0 -25px 30px;
        color: #000000;
        font-size: 30px;
        border-bottom: 1px solid #0087a9;
        padding: 15px 0;
    }

    a.bk-apint-txt {
        font-size: 24px;
        font-weight: 700;
        color: #2b3990;
        text-decoration: none;
    }

    .rate {
        font-size: 22px;
        font-weight: 700;
        // text-align: left;
    }

    .rate .rate-head {
        font-weight: 300;
        color: #3f4040;
    }

    a.bk-apint-txt:hover {
        color: #2b3990;
    }

    .wthot-insu .rate {
        font-size: 24px;
    }

    .price-tabs {
        margin-bottom: 100px;
    }

    .price-tabs .col .urgent-care-tab {
        background: rgba(85, 49, 140, .1);
    }

    .price-tabs .col>div {
        padding: 0 15px;
        padding-bottom: 20px;
        height: 100%;
    }
.price-tabs > .row > .col{
    padding: 5px
}
    .price-tabs .col .urgent-care-tab header {
        background: rgba(85, 49, 140, 1);
    }

    .price-tabs h5 {
        text-align: left;
    }

    .price-tabs .counseling-tab .rate {
        text-align: left;
    }

    .price-tabs .col header {
        padding: 15px;
        font-size: 24px;
        color: #fff;
        margin: 0 -15px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .price-tabs>.row>.col h5 {
        margin-bottom: 10px;
    }

    .price-tabs .col .urgent-care-tab .rate {
        color: rgba(85, 49, 140, 1);
    }

    .price-tabs .col .rate .rate-head {
        color: #3f4040;
        /* font-size: 18px; */
        font-weight: 400;
    }

    .price-tabs .col ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
    }

    .rate .rate-head {
        font-weight: 300;
        color: #3f4040;
    }

    .price-tabs .col .rate .slash {
        color: #3f4040;
        font-weight: 400;
        font-size: 20px;
    }

    .price-tabs .col .urgent-care-tab .rate {
        color: rgba(85, 49, 140, 1);
        text-align: left;
    }

    .rate .rate-head {
        font-weight: 300;
        color: #3f4040;
    }

    .price-tabs>.row>.col .row {
        margin-top: 10px;
    }

    .price-tabs .col ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .price-tabs .col ul li {
        margin-bottom: 10px;
    }

    .price-act {
        text-align: center;
        margin-top: 30px;
        position: relative ;
        bottom: 0px;
        left: 0;
        right: 0;
       
    }
    .price-tabs > .row > .col h5 {
        margin-bottom: 10px;
    }

    .price-act a {
        color: #fff;
    }

    .price-tabs .col .psychiatry-tab {
        background: rgba(42, 163, 212, .1);
    }

    .price-tabs .col .psychiatry-tab header {
        background: rgba(42, 163, 212, 1);
    }

    .psychiatry-tab h5 {
        text-align: left;
        font-weight: 500;
    }

    .button .pri-button {
        background: #2b3990;
    }

    .price-tabs .col .counseling-tab {
        background: rgba(101, 171, 60, .1);
    }

    .price-tabs .col .counseling-tab header {
        background: rgba(4, 118, 208, 1);
    }

    .home-container .price-tabs .col .rate .rate-head {
        color: #3f4040;
        /* font-size: 18px; */
    }

    .home-container .rate .rate-head {
        font-weight: 300;
        color: #3f4040;
    }

    .price-tabs .col .psychiatry-tab .rate {
        color: rgba(42, 163, 212, 1);
    }

    .price-tabs .col .counseling-tab .rate {
        color: rgba(4, 118, 208, 1);
    }

    .psychiatry-tab .rate {
        text-align: left;
    }

    // doctors tabs
    .doc-tabs .col {
        padding: 5px;
    }

    .doc-tabs .col .doc-sec {
        background: #00c9fb;
        padding: 30px 8px;
        text-align: center;
        color: #fff;
    }

    .doc-tabs .col .doc-sec {
        background: #00c9fb;
        padding: 30px 8px;
        text-align: center;
        color: #fff;
    }

    .doc-tabs .col .doc-img {
        padding-top: 100%;
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin: auto;
        margin-bottom: 35px;
    }

    .doc-tabs .col .doc-img img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
    }

    .doc-spec {
        font-size: 28px;
    }

    a.doc-link {
        font-size: 24px;
        font-weight: 600;
        color: #103e51;
        text-decoration: none;
    }

    .become-doc {
        text-align: center;
        margin: 50px auto 75px;
        font-size: 34px;
        font-weight: 300;
    }

    .become-doc-txt {
        margin-bottom: 30px;
    }

    .become-doc strong {
        font-weight: 500;
    }

    .button.pri-button {
        background: #2b3990;
        color: #fff;
        text-decoration: none;
    }

    .doc-bnr-cntnt h1 {
        font-size: 44px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: left;
    }

    .doc-bnr-cntnt .doc-banner-text {
        text-align: left;
    }

    a.see-more-doc {
        font-size: 24px;
        font-weight: 700;
        color: #2b3990;
        text-decoration: none;
    }
    .ps-child .price-act a{
        background-color: rgba(42,163,212,1);
    }
    .adult-tab .price-act a{
        background-color: rgba(4,118,208,1);
    }
    .intake .price-act a{
        background-color: rgba(42,163,212,1);
    }
    // .psy-adult{
    //     text-align: center;
    //     margin-top: 30px;
    //     // position: absolute;
    //     bottom: 30px;
    //     left: 0;
    //     right: 0;
    // }
    // .bt {
    //     font-size: 22px;
    //     font-weight: 600;
    //     text-transform: uppercase;
    //     padding: 10px 25px;
    //     color: #fff;
    //     display: inline-block;
    //     text-align: center;
    //     box-sizing: border-box;
    // }

    @media only screen and (max-width : 992px) {

        .doc-tabs .col,
        .price-tabs>.row>.col,
        .all-tabs .col {
            width: 50%;
            flex-basis: auto;
            padding: 5px 10px;
        }
    }
    .doc-banner-bx {
        position: relative;
    }

    .doc-banner {
        height: 610px;
        background: url("../../assets/img/doc-bg.jpg") top right no-repeat #e5e6ec;
        margin-bottom: 30px;
    }

    .app-process {
        background: #e5e6ec;
        padding: 40px 0 75px;
        text-align: center;
    }

    .app-process h1 {
        margin-bottom: 30px;
    }

    .app-process .col .process-text {
        font-weight: 500;
        margin-bottom: 10px;
    }

    .app-process .col img {
        width: 100%;
    }

    .app-bx {
        position: relative;
    }

    .app-banner {
        height: 477px;
        background: url("../../assets/img/app-bg.jpg") top right no-repeat #f2f2f2;
    }

    .tb-content,
    .price-bnr-cntnt,
    .doc-bnr-cntnt,
    .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        /* left: 15%; */
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .app-bnr-cntnt h1 {
        color: #2b3990;
        font-size: 44px;
        text-align: left;
    }

    .app-bnr-cntnt h4 {
        text-align: left;
    }

    .app-bnr-cntnt h4 {
        color: #181317;
        font-size: 44px;
        /* font-weight: 300; */
        margin-bottom: 30px;
    }

    .app-download-link a {
        display: inline-block;
        margin-right: 10px;
    }

    .card-col {
        visibility: none;
    }

    // footer
    .main-footer {
        background: #00c9fb;
        color: #fff;
        padding: 30px 0;
        font-size: 20px;
        font-weight: 300;
        margin-top: 50px;
    }

    .main-footer  ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
    }

    .main-footer  ul li {
        margin-bottom: 15px;
    }

    .main-footer  strong {
        font-weight: 600;
    }

    .main-footer  a {
        color: #fff;
        text-decoration: none;
    }
    .social-sec {
        text-align: center;
        margin: 30px auto;
    }
    .social-sec a {
        display: inline-block;
        margin: 0 10px;
        vertical-align: bottom;
    }
    
    .privacy-sec {
        background: #2b3990;
        padding: 10px 0;
        font-size: 18px;
        text-align: center;
    }

    .privacy-sec a {
        color: #f2f0f5 !important;
        display: inline-block;
        margin: 0 10px;
        text-decoration: none;
    }

    @media only screen and (max-width: 768px) {
        .price-tabs h5 {
            font-size: 16px;
        }

        .price-tabs .rate {
            font-size: 16px;
        }

        .price-tabs .col ul li {
            font-size: 16px;
        }

        .home-container .price-tabs .col>div {
            padding: 0 5px;
            padding-bottom: 80px;
            height: 100%;
        }

        .button {
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 10px 25px;
            color: #fff;
            display: inline-block;
            text-align: center;
            box-sizing: border-box;
        }

        .price-tabs .row {
            // display: flex;
        }

        .price-banner-bx,
        .doc-banner-bx {
            margin-bottom: 70px;
        }

        .price-banner,
        .doc-banner {
            background-size: contain;
            height: 262px;
        }

        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }

        .tb-content,
        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            position: inherit;
            top: auto;
            left: auto;
            -ms-transform: none;
            transform: none;
        }

        .price-bnr-cntnt h1,
        .doc-bnr-cntnt h1,
        .app-bnr-cntnt h1 {
            font-size: 34px;
            font-weight: 600;
        }

        // doc banner 
        .price-banner-bx,
        .doc-banner-bx {
            margin-bottom: 70px;
        }

        .price-banner,
        .doc-banner {
            background-size: contain;
            height: 262px;
        }

        .price-banner-bx,
        .doc-banner-bx {
            margin-bottom: 70px;
        }

        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }

        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }

        .tb-content,
        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            position: inherit;
            top: auto;
            left: auto;
            -ms-transform: none;
            transform: none;
        }

        .top-banner {
            background-image: url("../../assets/img/top-header-bg-mob.jpg");
            height: 528px;
            background-repeat: no-repeat;
            background-color: #f4f9f5;
            background-position: top center;
            position: relative;
            margin-bottom: 25px;

        }

        .app-banner {
            height: 432px;
            background: url("../../assets/img/app-bg-mob.jpg") top right no-repeat #e5e6ec;
            margin-bottom: 30px;
        }

        .tb-content h1 {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 10px;
            text-align: center;
        }

        .top-banner .tb-content {
            text-align: center;
            bottom: 10px;
            position: absolute;
            background: rgba(255, 255, 255, .8);
            right: 0;
            left: 0;
            margin: auto;
            width: 95%;
            padding: 5px 15px 15px;
        }

        .logo img {
            width: 100%;
        }

        .all-tabs {
            margin-bottom: 100px;
            display: flex;
            flex-wrap: wrap;
        }

        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }
        .main-footer  ul {
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }
    }



    @media only screen and (max-width: 480px) {

        .doc-tabs .col,
        .price-tabs>.row>.col,
        .all-tabs .col {
            width: 100%;
        }

        .all-tabs {
            margin-bottom: 100px;
            display: block;
        }

        .price-tabs .card-col {
            display: contents;
        }

        .doc-tabs .col,
        .price-tabs>.row>.col,
        .all-tabs .col {
            width: 100%;
        }

        .logo img {
            width: 100%;
        }

        .row-eq-height {
            width: 100%;
            display: inline;

        }

        .price-tabs .col {
            margin-top: 10px;
        }

        .price-banner,
        .doc-banner {
            background-size: contain;
            height: 262px;
        }

        .doc-bnr-cntnt h1 {
            font-size: 34px;
            font-weight: 600;

            text-align: center;
        }

        .doc-bnr-cntnt .doc-banner-text {
            text-align: center;
        }

        .tb-content,
        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            position: inherit;
            top: auto;
            left: auto;
            -ms-transform: none;
            transform: none;
        }

        .app-banner {
            height: 432px;
            background: url("../../assets/img/app-bg-mob.jpg") top right no-repeat #e5e6ec;
            margin-bottom: 30px;
        }

        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }

        .price-bnr-cntnt,
        .doc-bnr-cntnt,
        .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }

        .price-bnr-cntnt h1,
        .doc-bnr-cntnt h1,
        .app-bnr-cntnt h1 {
            font-size: 34px;
            font-weight: 600;
        }

        .app-bnr-cntnt h1 {
            text-align: center;
        }

        .app-bnr-cntnt h4 {
            font-size: 24px;
            text-align: center;
        }

        .app-download-link {
            padding-bottom: 50px;
        }

        .app-download-link a {
            display: block;
            margin-bottom: 20px;
        }
        // footer
        .main-footer  ul {
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }


    }
    @media (min-width: 1200px){
        .container {
            max-width: 1140px;
        }
    }
    

    
      
      

}