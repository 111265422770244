.terms-condition{
    .terms-condition-container{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        color: #51514f;
        font-size: .875rem;
        letter-spacing: 0;
        line-height: 32px;
        padding: 1%;
        margin-top: 20px;
    }
    p{
        font-size: 20px;
        color: #51514f;
    }
    .privacy{
        font-weight: 600;
        font-size: 20px;
        margin-top: 30px;
    }
    .dot{
        font-size: 30px;
        color: #00c9fb;
        font-weight: bold;
    }
    svg{
        color: #00c9fb;
    }
    .privacy-para{
        margin-top: 20px;
    }
    .use-services{
        margin-top: 15px;
    }
    .agree button {
        background: #00c9fb;
        width: 200px;
        border-radius: 20px;
    }
    .service{
        font-weight: 600;
        font-size: 20px;
    }
}