.overview {
    .top-banner {
        height: 528px;
        background-image: url("../../assets/img/top-header-bg.jpg");
        background-repeat: no-repeat;
        background-color: #f4f9f5;
        background-position: top center;
        position: relative;
        margin-bottom: 25px;
    }

    .inside-banner.top-banner .tb-content {
        width: 38%;
    }

    .top-banner .tb-content {
        left: auto;
        /* right: 15%; */
        padding: 20px;
        /* background: rgba(255,255,255,.3); */
    }

    .inside-banner h2 {
        font-size: 38px;
        font-weight: 700;
    }

    .tb-content h2 {
        color: #2b3990;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .inside-banner {
        height: 400px;
        margin-bottom: 100px;
    }

    .tb-content,
    .price-bnr-cntnt,
    .doc-bnr-cntnt,
    .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        /* left: 15%; */
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .inside-container {
        margin-bottom: 100px;
    }

    .inside-container.ins-cont-lft .row,
    .inside-container.ins-cont-rt .row {
        display: block;
    }

    .ins-cont-lft .ins-cont-img {
        float: right;
    }

    .ins-cont-img img {
        width: 100%;
    }

    .ins-cont-lft .ins-cont-txt {
        padding-right: 20px;
        float: left;
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .inside-container h3 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 20px;
        color: #2b3990;
    }

    .inside-container p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .ins-cont-rt .ins-cont-img {
        float: left;
    }

    .ins-cont-rt .ins-cont-txt {
        padding-left: 20px;
        float: right;
    }

    .preventative-care {
        padding: 50px;
        margin-bottom: 100px;
    }

    .grey-container {
        background: #e5e6ec54;
    }

    .preventative-care .inside-container {
        margin-bottom: 0;
    }

    .inside-center {
        text-align: center;
    }

    .inside-container h4 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .compare-doc {
        margin-bottom: 100px;
    }

    .text-left {
        text-align: left !important;
    }

    .table {
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
    }

    .table td,
    .table th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    .bd-content td:nth-child(even) {
        background: #F6F7F8 !important;
    }

    .table td,
    .table th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    .app-bx {
        position: relative;
    }

    .app-banner {
        height: 477px;
        background: url("../../assets/img/app-bg.jpg") top right no-repeat #f2f2f2;
    }

    .tb-content,
    .price-bnr-cntnt,
    .doc-bnr-cntnt,
    .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        /* left: 15%; */
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .app-bnr-cntnt h1 {
        color: #2b3990;
        font-size: 44px;
    }

    .app-bnr-cntnt h4 {
        color: #181317;
        font-size: 44px;
        /* font-weight: 300; */
        margin-bottom: 30px;
    }

    .app-bnr-cntnt p {
        font-size: 22px;
        font-weight: 300;
        margin-bottom: 30px;
    }

    .app-download-link a {
        display: inline-block;
        margin-right: 10px;
    }

    @media only screen and (max-width: 768px) {
        .app-download-link {
            padding-bottom: 50px;
        }
        .app-banner {
            height: 432px;
            background: url("../../assets/img/app-bg-mob.jpg") top right no-repeat #e5e6ec;
            margin-bottom: 30px;
        }
        .price-bnr-cntnt, .doc-bnr-cntnt, .app-bnr-cntnt {
            padding: 0 10px;
            text-align: center;
        }
        .tb-content, .price-bnr-cntnt, .doc-bnr-cntnt, .app-bnr-cntnt {
            position: inherit;
            top: auto;
            left: auto;
            -ms-transform: none;
            transform: none;
        }
        .price-bnr-cntnt h1, .doc-bnr-cntnt h1, .app-bnr-cntnt h1 {
            font-size: 34px;
            font-weight: 600;
        }
        .app-bnr-cntnt h4 {
            font-size: 24px;
        }
        .app-bnr-cntnt p {
            font-size: 18px;
        }
        .app-download-link {
            padding-bottom: 50px;
        }
        .app-download-link a {
            display: block;
            margin-bottom: 20px;
        }
        footer.main-footer{
            text-align: center;
        }
    }
}