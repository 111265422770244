.Location-container {
    background-color: #f4f9f5;
    h2 {
        text-transform: uppercase;
    }

    .location-body {
        width: 100%;
        padding: 50px;
    }

    h2 {
        font-size: 40px;
        font-weight: 500;
        color: #2A4358;
    }

    h5 {
        text-transform: uppercase;
        margin-top: 50px;
        font-size: 36px;
        font-weight: 300;

    }
    .list{
        display: grid;
        column-gap: 10px;
        grid-template-columns: auto auto auto;
    }
    .state-name{
        font-size: 36px;
    }
    p{
        text-transform: capitalize;
        font-size: 25px;
        font-weight: 300;
        color: #808080;
    }
    @media only screen and (max-width: 600px) {
        .list{
            display: block;
        }
        h2{
            font-size: 20px;
        }
        h5, .state-name{
            font-size: 18px;
        }
    }
}