.provider-container {
    background-color: #f4f9f5;
    .provider-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px;
        background-color: #EEF7FC;
        height: 430px;
        border: 1px solid #EEF7FC;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    }

    .h2-text {
        font-size: calc(1.325rem + .9vw);
        font-weight: 400;
    }

    p {
        color: #2D3844;
    }

    .prpvider-btns {
        display: flex;
        justify-content: center;
        padding: 10px;
    }

    .btns-calls {
        margin-right: 10px;
        height: 60px;
        width: 180px;
        border-radius: 37px;
        color: #2A4358;
    }

    .btn-call {
        background-color: #EEF7FC;
        border-color: #2A4358;
        width: 220px;

    }

    .btns-calls:hover {
        color: #2A4358;
    }

    .star-rating {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        margin-right: 30px;
        font-size: 35px;
        color: #ffc107;
    }

    .rating {
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        .h2-text {
            font-size: 18px;
            font-weight: 500;
        }

        .btn-call {
            width: auto;
        }

        .star-rating {
            margin-right: 0px;
        }

        .header-text {
            padding: 10px;
        }

        p {
            font-size: 15px;
        }
        .provider-box {
            margin: 0px
        }

    }
}