/* BookingProcess.scss */
.register-container {
    background-color: white;
    height: 100vh;

    .css-1yb974r {
        color: rgb(40, 55, 121);
        text-align: right;
        font-size: 14px;
    }

    .css-qs8l1l {
        padding: 0.75rem 20px 0 20px;
    }

    .css-s4dwsg:hover {
        text-decoration: none;
        cursor: pointer;
    }

    .css-10j4bz8 {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        justify-content: center;
        margin: 0px auto;
    }

    .css-1dpzekd {
        border: 1px solid rgb(205, 211, 218);
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        margin-top: 10px;
    }

    .css-10j4bz8 .booking-registration {
        margin: 0px;
    }

    .columns.is-variable.is-1 {
        --columnGap: 0.25rem;
    }

    .columns.is-variable>.column {
        padding-left: var(--columnGap);
        padding-right: var(--columnGap);
    }

    .columns.is-variable {
        --columnGap: 0.75rem;
        margin-left: calc(-1* var(--columnGap));
        margin-right: calc(-1* var(--columnGap));
    }

    .css-ywe0ls {
        display: flex;
        flex-flow: column wrap;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 32px 0px 16px;
        margin: 0px 10px 48px;
        color: rgb(40, 55, 121);
        max-width: 500px;
    }

    .css-jgn4uk:hover {
        color: rgb(40, 55, 121);
        transform: scale(1);
    }

    .columns.is-multiline {
        flex-wrap: wrap;
    }

    .columns.is-centered {
        justify-content: center;
    }

    .columns:last-child {
        margin-bottom: -.75rem;
    }

    .columns {
        margin-left: -.75rem;
        margin-right: -.75rem;
        margin-top: -.75rem;
    }

    .css-1bogmwi {
        margin: -5px auto 5px;
    }

    article,
    aside,
    figure,
    footer,
    header,
    hgroup,
    section {
        display: block;
    }

    .css-639bzm {
        max-width: 100%;
        margin: 0px auto;
    }

    .columns.is-gapless:last-child {
        margin-bottom: 0;
    }

    .columns.is-vcentered {
        align-items: center;
    }

    .columns.is-mobile>.column.is-one-third {
        flex: none;
        width: 33.3333%;
    }

    .columns.is-gapless>.column {
        margin: 0;
        padding: 0 !important;
    }

    .css-1i576z1 {
        margin: 12px 0px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        color: rgb(0, 117, 228);
        margin-left: 5px;
    }

    .css-1ruqnf0 {
        font-size: 24px;
        color: rgb(40, 55, 121);
        font-weight: 600;
    }

    .css-639bzm .e18q9v4c1 {
        margin-top: 60px;
    }

    .css-jgn4uk {
        position: relative;
        z-index: 1;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border: 0px;
        border-radius: 27px;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        letter-spacing: 0.25px;
        text-decoration: none;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
        background: rgb(255, 183, 76);
        color: rgb(40, 55, 121);
    }

    .css-jgn4uk::before {
        box-shadow: rgb(178, 221, 237) 0px 0px 0.01px inset;
    }

    .css-jgn4uk::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27px;
        background: transparent;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
    }

    .css-s4dwsg {
        position: relative;
        z-index: 1;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border-radius: 27px;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        letter-spacing: 0.25px;
        text-decoration: none;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
        border: 1px solid rgb(255, 183, 76);
        background: rgb(255, 255, 255);
        color: rgb(40, 55, 121);
    }

    .css-s4dwsg::before {
        box-shadow: rgb(255, 183, 76) 0px 0px 0.01px inset;
    }

    .css-s4dwsg::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27px;
        background: transparent;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    #pay .css-s4dwsg:hover {
        color: rgb(40, 55, 121);
        transform: scale(1);
    }

    .css-1dpzekd .doctor-name {
        color: rgb(0, 0, 0);
        font-size: 21px;
        line-height: 24px;
        font-weight: 400;
    }

    .css-gl2qrx {
        display: flex;
        -webkit-box-align: baseline;
        align-items: baseline;
    }

    .css-5nwnwu {
        display: flex;
        align-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 20px;
        font-family: Times;
        line-height: 1;
        margin-bottom: 10px;
    }

    .css-cjt61t {
        font-size: 18px;
        font-weight: 400;
    }

    .css-cjt61t {
        font-size: 18px;
        font-weight: 400;
    }

    .css-1q7wp66 {
        display: flex;
        margin-right: 8px;
        -webkit-box-align: center;
        align-items: center;
        min-width: 140px;
    }

    .css-1q7wp66 span {
        margin: 4px 6px 0px;
    }

    span {
        font-style: inherit;
        font-weight: inherit;
    }

    .css-5nwnwu::before {
        content: "★★★★★";
        letter-spacing: 3px;
        background: linear-gradient(90deg, rgb(255, 183, 76) 92%, rgb(99, 121, 143) 92%) text;
        -webkit-text-fill-color: transparent;
    }

    @media (min-width: 600px) {
        .css-jgn4uk {
            font-size: 20px;
            line-height: 1;
        }
    }



    .css-1fttcpj {
        display: flex;
        flex-direction: column;
    }

    @media print,
    screen and (min-width: 768px) {
        .columns:not(.is-desktop) {
            display: flex;
        }
    }

    @media screen and (min-width: 1408px) {

        .column.is-5-fullhd {
            flex: none;
            width: 41.66666674%;
        }
    }

    @media screen and (min-width: 1024px) {

        .column.is-four-fifths-desktop {
            flex: none;
            width: 80%;
        }
    }

}