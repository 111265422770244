.therapists{
    margin-top: 80px;

    .accordion-button:not(.collapsed)::after{
        display: none;
    }
    .accordion-button{
        background-color: #fff;
        font-size: 25px;
        color: #000;
        font-weight: 600;
        cursor:auto;
    }
    .doc-image{
        width: 103px;
    }
    .doc-details{
        margin-left: 10px    ;
    }
    #collapseOne{
        margin: 10px;
    }
    .client-review{
        text-decoration: underline;
        text-decoration-color: #00c9fb;
        color: #919699;
      
    }
    .exp{
        line-height: 0px;
        color: #919699;
    }
    .timeslots{
        margin-top: 10px;
    }
    .time-date{
        line-height: 0px;
        color: #919699;
    }
    .slot-text{
        font-size: 20px;
        font-weight: 500;
        color: #919699;
    }
    .time-btn{
        font-size: 20px;
        background-color: #00c9fb;
        box-shadow: none;
    }
    .time-btn:hover{
        background-color: #00c9fb;
    }
    .more{
        font-size: 20px;
        font-weight: 500;
        border-color: #00c9fb;
        color: #000;
    }
    .more-icon{
        color: #00c9fb;
    }
    .timings-btn{
        margin-top: 20px;
    }
}