.main-footer{
    background: #00c9fb;
    color: #fff;
    padding: 30px 0;
    font-size: 20px;
    font-weight: 300;
    margin-top: 50px;
     // footer

      ul {
        padding: 0 !important;
        margin: 0 !important;
        list-style: none !important;
    }
    ul li a{
        color: #fff !important;
    }

    ul li {
        margin-bottom: 15px;
    }

    strong {
        font-weight: 600;
        
    }

      a {
        color: #fff;
        text-decoration: none !important;
    }
    .social-sec {
        text-align: center;
        margin: 30px auto;
    }
    .social-sec a {
        display: inline-block;
        margin: 0 10px;
        vertical-align: bottom;
    }
    
    
}
.privacy-sec {
    background: #2b3990 !important;
    padding: 10px 0 !important;
    font-size: 18px !important;
    text-align: center !important;
}

.privacy-sec a {
    color: #f2f0f5 !important;
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
}