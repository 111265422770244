.appointement {
    img {
        width: 100px;
        border-radius: 60px;
        height: 130px;
    }

    .doc-details {
        padding-left: 10px;
    }

    .doc-name {
        font-size: 26px;
        color: #92c9e0;
    }

    .doc-prof {
        font-size: 20px;

    }

    .doc-timings {
        // margin-top: 15px;
        flex-wrap: wrap;
    }

    .doc-timings button {
        width: 150px;
        background-color: #2d434a;
        color: #fff;
        box-shadow: none;

    }

    .timings-btn,
    .more {
        margin-left: 10px;
    }

    .more {
        background: #fff !important;
        color: #000 !important;
        border: 1px solid !important;
        box-shadow: none !important;
    }
    .doc-prof-details{
        display: flex;
        justify-content: space-between;
    }
    .doc-date{
        text-align: left;
        font-size: 20px;
    }
    .doctor-container{
        display: flex;
    }
    @media only screen and (max-width: 600px) {
        .doctor-container{
            display: block;
        } 
        .doc-name{
            font-size: 18px;
        }
        .doc-prof, .doc-day{
            font-size: 14px;
        }
        .doc-date{
            font-size: 15px;
        }
        .doc-image{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 37%;
        }
    }
}