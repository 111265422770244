.banner-text-container {
    background-color: #F8F5EE;
    display: grid;
    place-items: center;
    height: 480px;

    h1 {
        font-size: 3.5rem;
        text-transform: capitalize;

    }

    .banner-body {
        padding: 20px;
    }

    p a {
        font-size: 16px;
        line-height: 24px;
        font-family: "gibson-semibold";
        border-radius: 100px;
        height: 50px;
        width: 230px;
        padding: 13px 30px;
        display: block;
        text-align: center;
        background: #ffcc40 !important;
        margin-top: 33px;
        margin-bottom: 40px;
        text-decoration: none;
        color: #000;
        font-size: 16px;
        font-weight: 600;
    }

    @media (min-width: 1200px) {
        h1 {
            font-size: 3.5rem;
            text-transform: capitalize;
        }

    }
    @media only screen and (max-width: 600px) {
        height: 250px;
        h1{
            text-align: center;
            font-size: 2rem;
        }
        p a{
            width: auto;
        }
    }
}