.member-login{
    .main-login-bx {
        margin-top: 40px;
        border: none;
        border-radius: 0;
    }
    .card{
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border-radius: 0.25rem;
    }
    .log-reg-form {
        padding: 15px 20px;
    }
    .log-reg-form h2 {
        margin-bottom: 10px;
    }
    .log-reg-form h5 {
        font-weight: 300;
        opacity: .7;
        margin-bottom: 15px;
    }
    .form-group {
        margin-bottom: 1rem;
    }
    .double-bounce1, .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #333;
        opacity: .6;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-animation: 2s ease-in-out infinite sk-bounce;
        animation: 2s ease-in-out infinite sk-bounce;
    }
    
    h2, h5{
        padding:0px;
        margin: 0px;
    }
    .login-image {
        background: url("../../../assets/img/login-bg.jpg") top right no-repeat #e5e6ec;
        background-size: cover;
    }
    
        .form-group button{
            background: #262628;
            color: #fff;
            margin-top: 20px;
            padding: 10px 30px;
            font-size: 18px;
            border-radius: 10px;
            -webkit-transition: .3s;
            transition: all .3s ease;
        }
        a.reg{
            color: #2b3990;
        }
        
}
// register
.register-here{
    .main-login-bx {
        margin-top: 40px;
        border: none;
        border-radius: 0;
    }
    .card{
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border-radius: 0.25rem;
    }
    .log-reg-form {
        padding: 15px 20px;
    }
    .log-reg-form h2 {
        margin-bottom: 10px;
    }
    .log-reg-form h5 {
        font-weight: 300;
        opacity: .7;
        margin-bottom: 15px;
    }
    .form-group {
        margin-bottom: 1rem;
    }
    .double-bounce1, .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #333;
        opacity: .6;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-animation: 2s ease-in-out infinite sk-bounce;
        animation: 2s ease-in-out infinite sk-bounce;
    }
    
    h2, h5{
        padding:0px;
        margin: 0px;
    }
    .login-image {
        background: url("../../../assets/img/login-bg.jpg") top right no-repeat #e5e6ec;
        background-size: cover;
    }
    
        .form-group button{
            background: #262628;
            color: #fff;
            margin-top: 20px;
            padding: 10px 30px;
            font-size: 18px;
            border-radius: 10px;
            -webkit-transition: .3s;
            transition: all .3s ease;
        }
        a.reg{
            color: #2b3990;
        }
        

}