.main-header {
    height: 70px;
    background: #fff;
    padding: 0 1rem;

    .navbar-nav .home,
    .overview,
    .facilities {
        text-transform: uppercase;
        font-weight: 500;
        color: #2b3990 !important;
    }

    .member #basic-nav-dropdown {
        text-transform: uppercase;
        font-weight: 500;
        color: #2b3990 !important;
        font-weight: 500;
    }

    .member-login {
        margin-right: 10px;
    }

    .member-login,
    .member-btn {
        background: #2b3990 !important;
        border: none;
        border-color: none;
        color: "#fff" !important;
    }

    .client-login,
    #input-group-dropdown-1 {
        color: #fff;
        background-color: #00c9fb;
        border: none;
    }

    .navbar-nav .nav-item {
        margin-right: 20px;
    }
    .navbar-nav .nav-item:nth-child(3){
        margin-right: 10px;
    }

    .navbar-nav .nav-item a, .member #basic-nav-dropdown{
        font-weight: 400;
    }


}