.appointment-insu {
    background: white;
    height: 100vh;

    .css-1d0nbku {
        margin-top: 24px;
    }

    .css-vi6xve {
        border-bottom: 1px solid rgb(233, 238, 242);
        padding: 28px 0px;
    }

    .column {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding: .75rem;
    }

    .css-vi6xve .is-vcentered {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .css-zdqaz8 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    .css-1gj2y34 {
        color: rgb(79, 79, 79);
        display: flex;
    }

    .css-1h82gey {
        display: flex;
        align-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 20px;
        font-family: Times;
        line-height: 1;
        margin-bottom: 10px;
    }

    .css-8c5eq6 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .css-9m3rcd {
        display: flex;
        flex: 1 1 0%;
        -webkit-box-align: center;
        align-items: center;
        line-height: 1;
        color: rgb(0, 117, 228);
        cursor: pointer;
        margin-bottom: 12px;
    }

    .css-9m3rcd>span {
        font-size: 16px;
        line-height: 11px;
        margin-top: 0px;
        margin-left: 0px;
    }

    span {
        font-style: inherit;
        font-weight: inherit;
    }

    .css-1k2ndej {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(79, 79, 79);
    }

    .columns.is-mobile>.column.is-narrow {
        flex: none;
        width: unset;
    }

    .css-1qxxg33::before {
        box-shadow: rgb(178, 221, 237) 0px 0px 0.01px inset;
    }

    .css-1k2ndej {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(79, 79, 79);
    }

    .css-1qxxg33::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27px;
        background: transparent;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
    }

    @media (min-width: 600px) {

        .css-1qxxg33 {
            font-size: 14px;
            line-height: 1;
        }
    }

    .css-1qxxg33 {
        position: relative;
        z-index: 1;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: 40px;
        max-height: none;
        padding: 14px 20px 11px;
        border: 0px;
        border-radius: 27px;
        font-weight: 600;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.25px;
        text-decoration: none;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
        background: rgb(255, 183, 76);
        color: rgb(40, 55, 121);
    }

    .css-vi6xve .is-vcentered {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

    .css-1q9rfie {
        border-radius: 50%;

    }

    .columns.is-mobile>.column.is-narrow {
        flex: none;
        width: unset;
    }

    .css-1wdwtsi {
        display: flex;
        width: 100%;
        height: 24px;
        line-height: 1;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: left;
        color: rgb(51, 180, 210);
    }

    .css-qs8l1l {
        padding: 0.75rem 20px 0 20px;
    }

    .columns.is-multiline {
        flex-wrap: wrap;
    }

    .columns.is-centered {
        justify-content: center;
    }

    .columns:last-child {
        margin-bottom: -.75rem;
    }

    .columns {
        margin-left: -.75rem;
        margin-right: -.75rem;
        margin-top: -.75rem;
    }

    .css-1bogmwi {
        margin: -5px auto 5px;
    }

    article,
    aside,
    figure,
    footer,
    header,
    hgroup,
    section {
        display: block;
    }

    .css-1fttcpj {
        display: flex;
        flex-direction: column;
    }

    .css-jj2l16 {
        line-height: 1;
        margin: 16px 0px 8px;
    }

    .css-yuipzk {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 8px;
    }

    .react-datepicker-wrapper {
        display: inline-block;
        padding: 0;
        border: 0;
    }

    .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    .css-yuipzk {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 8px;
    }

    .css-2rd4fw-container {
        position: relative;
        box-sizing: border-box;
    }

    .css-1r5gb7q {
        display: inline-block;
        padding-left: 4px;
    }

    [role=button] {
        cursor: pointer;
    }

    .css-7pg0cj-a11yText {
        z-index: 9999;
        border: 0px;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        position: absolute;
        overflow: hidden;
        padding: 0px;
        white-space: nowrap;
    }

    span {
        font-style: inherit;
        font-weight: inherit;
    }

    .css-2rd4fw-container .react-select__control {
        margin-right: 8px;
        width: 140px;
    }

    .css-1r5gb7q {
        display: inline-block;
    }

    [role=button] {
        cursor: pointer;
    }

    .css-1wa6ghg {
        white-space: nowrap;
        color: rgb(0, 119, 230);
        display: inline-block;
        border-radius: 20px;
        background: rgb(255, 255, 255);
        padding: 7px;
        cursor: inherit;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(0, 119, 230);
        margin-right: 10px;
        margin-top: 0px;
        height: 38px;
        text-transform: capitalize;
    }

    .css-1ubpmw7 {
        margin-left: 7px;
        margin-right: 7px;
        cursor: inherit;
    }

    .css-2rd4fw-container .react-select__control {
        height: 36px;
        padding: 0px;
        margin: 0px;
        border: none;
        box-shadow: none;

    }

    .css-2rd4fw-container .react-select__control {
        appearance: none;
        font-size: 16px;
        line-height: 20px;
        color: rgb(40, 55, 121);
        border-radius: 8px;
    }

    .css-13cymwt-control {
        -webkit-box-align: center;
        align-items: center;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        position: relative;
        transition: all 100ms ease 0s;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        outline: 0px !important;
    }

    .css-2rd4fw-container .react-select__value-container {
        padding: 9px 12px;
        font-size: 16px;
        height: 36px;
        color: rgb(0, 117, 228);
        border: 1px solid currentcolor;
        border-radius: 20px;
        cursor: pointer;
    }

    .css-19bb58m::after {
        content: attr(data-value) " ";
        visibility: hidden;
        white-space: pre;
        grid-area: 1 / 2;
        font: inherit;
        min-width: 2px;
        border: 0px;
        margin: 0px;
        outline: 0px;
        padding: 0px;
    }

    .css-hlgwow {
        -webkit-box-align: center;
        align-items: center;
        display: grid;
        flex: 1 1 0%;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        padding: 2px 8px;
        box-sizing: border-box;
    }

    .css-2rd4fw-container .react-select__single-value {
        color: rgb(0, 117, 228);
        margin-top: 1px;
    }

    .css-2rd4fw-container .react-select__input-container {
        margin: 0px;
        padding: 0px;
    }

    .css-19bb58m {
        visibility: visible;
        flex: 1 1 auto;
        display: inline-grid;
        grid-area: 1 / 1 / 2 / 3;
        grid-template-columns: 0px min-content;
        color: rgb(51, 51, 51);
        box-sizing: border-box;
    }

    .css-1dimb5e-singleValue {
        grid-area: 1 / 1 / 2 / 3;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 2px;
        margin-right: 2px;
        box-sizing: border-box;
    }

    .css-10fk7tr {
        padding: 12px;
        margin-right: 8px;
        border: 1px solid rgb(0, 117, 228);
        color: rgb(0, 117, 228);
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        font-size: 16px;
        line-height: 14px;
        text-transform: none;
        appearance: none;
        display: inline-block;
        height: 36px;
    }

    body,
    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: TT Commons;
    }

    .css-qgd6qg {
        max-width: 100%;
        margin: 0px auto;
    }

    .css-1ruqnf0 {
        font-size: 24px;
        color: rgb(40, 55, 121);
        font-weight: 600;
    }

    .css-xz1kas {
        max-width: 100%;
        margin: 0px auto;
    }

    .css-1ruqnf0 {
        font-size: 24px;
        color: rgb(40, 55, 121);
        font-weight: 600;
    }

    .css-xz1kas .ei1rvit0 {
        margin: 30px 0px 10px;
    }

    .css-bq7cnj {
        font-size: 21px;
        color: rgb(40, 55, 121);
    }

    .columns.is-gapless:last-child {
        margin-bottom: 0;
    }

    .columns.is-vcentered {
        align-items: center;
    }

    .columns.is-gapless {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }

    .columns.is-mobile>.column.is-one-third {
        flex: none;
        width: 33.3333%;
    }

    .columns.is-gapless>.column {
        margin: 0;
        padding: 0 !important;
    }

    .column {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding: .75rem;
    }

    .css-xd0j1g {
        opacity: 1;
        outline: none 0px;
        border: none;
        cursor: pointer;
    }

    a {
        color: #485fc7;
        cursor: pointer;
        text-decoration: none;
    }

    .css-1i576z1 {
        margin: 12px 0px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        color: rgb(0, 117, 228);
    }

    .css-1yft4at {
        margin: 0px 10px 0px 0px;
    }

    img {
        height: auto;
        max-width: 100%;
    }

    img,
    svg {
        vertical-align: middle;
    }

    .columns.is-gapless>.column {
        margin: 0;
        padding: 0 !important;
    }

    .css-t2ye36 {
        color: rgb(0, 117, 228);
        cursor: pointer;
    }

    .css-rcr16b-container {
        position: relative;
        box-sizing: border-box;
    }

    .css-7pg0cj-a11yText {
        z-index: 9999;
        border: 0px;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        position: absolute;
        overflow: hidden;
        padding: 0px;
        white-space: nowrap;
    }

    .css-rcr16b-container .react-async-select__control {
        appearance: none;
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
        color: rgb(40, 55, 121);
        border: 1px solid rgb(178, 221, 237);
        border-radius: 8px;
        padding: 0px 6px 0px 0px;
        box-shadow: 0px 0px 0px 0px;
    }


    .css-13cymwt-control {
        -webkit-box-align: center;
        align-items: center;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        position: relative;
        transition: all 100ms ease 0s;
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        outline: 0px !important;
        width: 215px;
    }

    .css-hlgwow {
        -webkit-box-align: center;
        align-items: center;
        display: grid;
        flex: 1 1 0%;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        padding: 2px 8px;
        box-sizing: border-box;
    }

    .css-1wy0on6 {
        -webkit-box-align: center;
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
    }

    .css-xz1kas .e1v9kpko8 {
        max-width: 200px;
        margin: 30px auto auto;
    }


    .css-1fttcpj {
        display: flex;
        flex-direction: column;
    }

    .css-rcr16b-container .react-async-select__placeholder {
        color: rgb(99, 121, 143);
    }

    .css-jgn4uk:disabled,
    .css-jgn4uk:disabled:hover,
    .css-jgn4uk:disabled:hover::before,
    .css-jgn4uk.is-disabled,
    .css-jgn4uk.is-disabled:hover,
    .css-jgn4uk.is-disabled:hover::before {
        box-shadow: none;
        background: rgb(179, 187, 189);
        color: rgb(255, 255, 255);
        transition: none 0s ease 0s;
        cursor: default;
    }

    .css-jgn4uk::before {
        box-shadow: rgb(178, 221, 237) 0px 0px 0.01px inset;
    }

    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    .css-jgn4uk {
        position: relative;
        z-index: 1;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border: 0px;
        border-radius: 27px;
        font-weight: 600;

        letter-spacing: 0.25px;
        text-decoration: none;

    }

    @media (min-width: 600px) {

        .css-s4dwsg {
            font-size: 20px;
            line-height: 1;
        }
    }

    .css-s4dwsg {
        /* position: relative; */
        /* z-index: 1; */
        /* display: flex; */
        /* -webkit-box-pack: center; */
        /* justify-content: center; */
        -webkit-box-align: center;
        align-items: center;
        width: auto;
        max-width: none;
        min-width: 100px;
        height: auto;
        max-height: none;
        padding: 14px 20px 11px;
        border-radius: 27px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.25px;
        text-decoration: none;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
        border: 1px solid rgb(255, 183, 76);
        background: rgb(255, 255, 255);
        color: rgb(40, 55, 121);
    }

    .css-jgn4uk::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27px;
        background: transparent;
        transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1) 0s;
    }

    .css-1jqq78o-placeholder {
        grid-area: 1 / 1 / 2 / 3;

        margin-left: 2px;
        margin-right: 2px;
        box-sizing: border-box;
    }

    .css-19bb58m {
        visibility: visible;
        flex: 1 1 auto;
        display: inline-grid;
        grid-area: 1 / 1 / 2 / 3;
        grid-template-columns: 0px min-content;
        margin: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        color: rgb(51, 51, 51);
        box-sizing: border-box;
    }

    span {
        font-style: inherit;
        font-weight: inherit;
    }

    @media print,
    screen and (min-width: 768px) {
        .columns:not(.is-desktop) {
            display: flex;
        }

        .css-9tx5ga {
            display: none;
        }

    }

    @media screen and (min-width: 1408px) {
        .column.is-5-fullhd {
            flex: none;
            width: 41.66666674%;
        }
    }

    // @media print,
    // screen and (min-width: 768px) {
    //     &.columns:not(.is-desktop) {
    //         display: flex;
    //     }
    // }

    // @media screen and (min-width: 1408px) {
    //     .column.is-5-fullhd {
    //         flex: none;
    //         width: 41.66666674%;
    //     }
    // }

    // .css-wiv4hk {
    //     margin: -5px auto 5px;
    //     background: none;
    //     position: sticky;
    //     z-index: 3;
    //     top: 0px;
    // }

    // .columns.is-vcentered {
    //     align-items: center;
    // }

    // .columns.is-gapless:last-child {
    //     margin-bottom: 0;
    // }

    // .columns.is-vcentered {
    //     align-items: center;
    // }

    // .columns.is-gapless {
    //     margin-left: 0;
    //     margin-right: 0;
    //     margin-top: 0;
    // }

    // .columns.is-mobile>.column.is-one-third {
    //     flex: none;
    //     width: 33.3333%;
    // }

    // a {
    //     color: #485fc7;
    //     text-decoration: none;
    // }

    // .css-xd0j1g {
    //     opacity: 1;
    //     outline: none 0px;
    //     border: none;
    //     cursor: pointer;
    // }

    // .columns.is-gapless>.column {
    //     margin: 0;
    //     padding: 0 !important;
    // }

    // .columns.is-mobile>.column.is-one-third {
    //     flex: none;
    //     width: 33.3333%;
    // }

    // .columns.is-gapless>.column {
    //     margin: 0;
    //     padding: 0 !important;
    // }

    // .column {
    //     display: block;
    //     padding: .75rem;
    // }

    // .columns.is-multiline {
    //     flex-wrap: wrap;
    // }

    // .columns.is-centered {
    //     justify-content: center;
    // }

    // .columns:last-child {
    //     margin-bottom: -.75rem;
    // }

    // .columns {
    //     margin-left: -.75rem;
    //     margin-right: -.75rem;
    //     margin-top: -.75rem;
    // }
}