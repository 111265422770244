.about-doctor{
    .inside-banner {
        height: 400px !important;
        margin-bottom: 100px !important;
    }
    .top-banner {
        height: 528px;
        background-image: url("../../assets/img/top-header-bg.jpg");
        background-repeat: no-repeat;
        background-color: #f4f9f5;
        background-position: top center;
        position: relative;
        margin-bottom: 25px;
    }
    .inside-banner h2 {
        font-size: 38px;
        font-weight: 700;
    }
    .inside-banner.top-banner .tb-content {
        width: 42%;
    }
    .tb-content h2 {
        color: #2b3990;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .tb-content, .price-bnr-cntnt, .doc-bnr-cntnt, .app-bnr-cntnt {
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    // step1
    .inside-container {
        margin-bottom: 100px;
    }
    .ins-cont-img {
        height: 280px;
        overflow: hidden;
    }
    .ins-cont-rt .ins-cont-img {
        float: left;
    }
    .ins-cont-img img {
        width: 100%;
    }
    .ins-cont-rt .ins-cont-txt {
        padding-left: 20px;
        float: right;
    }
    .inside-container h3 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 20px;
        color: #2b3990;
    }
    .inside-container h3 .step {
        display: block;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .inside-container p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .button.pri-button {
        background: #2b3990;
        color: #fff;
    }
    .button {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 10px 25px;
        color: #fff;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        text-decoration: none;
    }
    // step 2
    .ins-cont-img {
        height: 280px;
        overflow: hidden;
    }
    .ins-cont-lft .ins-cont-img {
        float: right !important;
    }
    .ins-cont-img img {
        width: 100%;
    }
    .ins-cont-lft .ins-cont-txt {
        padding-right: 20px;
        float: left;
    }
    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }
    // .clearfix{
    //     display: inline;
    // }
    // step 3
    .inside-container.ins-cont-lft .row, .inside-container.ins-cont-rt .row {
        display: block;
    }
    .ins-cont-rt .ins-cont-txt {
        padding-left: 20px;
        float: right;
    }
    // mental health
    .preventative-care {
        padding: 50px;
        margin-bottom: 100px;
    }
    .grey-container {
        background: #e5e6ec54;
    }
    .preventative-care .inside-container {
        margin-bottom: 0;
    }
    
    .inside-center {
        text-align: center;
    }
    .inside-container h4 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    
    @media only screen and (max-width: 768px){
        .top-banner {
            background-image: url("../../assets/img/top-header-bg-mob.jpg");
        }

    }
    // mobile
    @media only screen and (max-width: 480px) {
        .top-banner {
            background-image: url("../../assets/img/top-header-bg-mob.jpg");
        }
        .inside-banner.top-banner .tb-content {
            width: 90%;
        }
        .top-banner .tb-content {
            text-align: center;
            bottom: 10px;
            position: absolute;
            background: rgba(255,255,255,.8);
            right: 0;
            left: 0;
            margin: auto;
            width: 95%;
            padding: 5px 15px 15px;
        }

    }
    
}