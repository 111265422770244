.counselors-container {
    .counselors-body {
        margin-top: 10px;
    }

    #lr-hero {
        background-position: center center !important;
        background: linear-gradient(90deg, rgba(0, 0, 0, .75) 70%, rgba(0, 0, 0, .6)), url("https://thriveworks.com/wp-content/themes/Thriveworks-Three/images/thriveworks-about-us-header.webp");
        background-repeat: no-repeat !important;
        background-size: cover !important;
    }

    .lp-hero-content {
        padding: 100px 0;
    }

    h2 {
        font-weight: 400 !important;
        font-size: 20px;
        line-height: 30px;
        max-width: 560px;
        padding-bottom: 10px;
        font-style: normal;
        color: #ffffff;
    }

    .lp-hero-content .hero-title {
        font-weight: 500 !important;
        font-size: 48px;
        line-height: 1;
        color: #ffffff;
        font-style: normal;
        margin: 0;
        outline: 0;
        border: 0;
        padding-bottom: 10px;
    }

    .hero-buttons {
        justify-content: flex-start;
        display: flex;
        margin-top: 20px;
        padding: 0;
    }

    .location-cta-book-btn {
        cursor: pointer !important;
        z-index: 10;
        transition: all 0.2s;
        text-align: center;
        text-decoration: none;
        min-width: 150px !important;
        align-items: center;
        font-weight: 500;
        line-height: 40px;
        padding: 0 24px !important;
        white-space: nowrap;
        font-size: 18px;
    }

    #lp-hero .hero-buttons a.location-cta-book-btn.rounded-button,
    #lp-hero .hero-buttons a.location-cta-book-btn {
        background: #ffcc40 !important;
        border-radius: 25px !important;
        border-color: #ffcc40 !important;
        color: #212529;
        margin-right: 9px;
    }

    #lp-hero .hero-buttons a.rounded-button {
        background: 0 0 !important;
        border-radius: 25px !important;
        border: 1px solid #fff !important;
        color: #fff;
        border: 1px solid #fff !important;
    }

    #second-top-menu.lp-second-menu {
        padding: 0;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        position: relative;
        z-index: 1;
    }

    .navbar-expand {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    #second-top-menu.lp-second-menu nav.navbar {
        padding: 0 1rem;
    }

    #second-top-menu.lp-horizontal #navbarSupportedContentTwo {
        display: block;
    }

    #second-top-menu #navbarSupportedContentTwo {
        width: 100%;
        display: none;
    }

    #second-top-menu ul.navbar-nav {
        flex-wrap: wrap;
        border-bottom: 1px solid #e7e7e7;
    }


    #second-top-menu.lp-horizontal #navbarSupportedContentTwo ul.navbar-nav>li.menu-item:nth-of-type(1) {
        flex-grow: 2;
        order: 1;
        margin-right: 0;
        border-bottom: none;
    }

    #second-top-menu.lp-horizontal #navbarSupportedContentTwo ul.navbar-nav>li.menu-item {
        padding: 0;
        margin: 0 15px;
    }

    .navbar-expand .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }

    #second-top-menu.lp-horizontal #navbarSupportedContentTwo ul.navbar-nav>li.menu-item:first-of-type>a {
        float: right;
        font-weight: 400 !important;
        font-size: 16px !important;
        text-align: right;
        color: #212529 !important;
    }

    #second-top-menu ul.navbar-nav li .nav-link,
    #second-top-menu ul.navbar-nav li .dropdown-item {
        color: #0d85f4;
        text-decoration: none;
        font-weight: 400;
        padding: 10;
        font-size: 16px;
        line-height: 33px;
    }

    .dropdown-toggle::after {
        color: #0d85f4;
    }

    .lp-horizontal nav div ul div .dropdown-toggle {
        color: #0d85f4;
    }

    #location-content,
    #location-content h1,
    #location-content h2,
    #location-content h3,
    #location-content h4,
    .location-subpage-heading .heading {
        font-weight: 400;
    }

    #location-content #lp-location-info,
    #location-content.sub-page {
        margin-top: 40px;
        // padding-bottom: 60px;
    }

    #location-content .bottom-cta,
    #location-content section {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    #location-content #lp-location-info .heading,
    #location-content #lp-services .heading,
    #main-content .heading,
    .location-subpage-heading .heading {
        font-size: 24px;
        font-weight: 500 !important;
        color: #212529;
        padding-bottom: 20px;
    }

    #location-content #lp-map iframe,
    .location-sub-page #lp-map iframe {
        width: 100%;
        height: 100%;
        max-height: 400px;
        max-width: 760px;
    }

    p.getting-title {
        padding: 15px 0 10px !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #212529;
    }

    p.getting-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #212529;
    }

    #location-content #lp-location-info .heading,
    #location-content #lp-services .heading,
    #main-content .heading,
    .location-subpage-heading .heading {
        font-size: 24px;
        font-weight: 500 !important;
        color: #212529;
        padding-bottom: 20px;
    }

    .location-sidebar {
        font-size: 16px;
        line-height: 24px;
    }

    .location-sidebar div.field {
        margin-bottom: 25px;

    }

    .location-sidebar .field .label,
    .location-sidebar span.location-text {
        font-weight: 500;
        padding-bottom: 10px;
    }

    .location-sidebar .label,
    .location-sidebar .field>span {
        display: block;
        font-style: normal;
        font-weight: 400;
        color: #212529;
    }

    a#lp-phone-value,
    #main-content .entry-content a {
        color: #0d85f4 !important;
        text-decoration: none;
    }

    .location-sidebar div.field {
        margin-bottom: 25px;
    }


    .location-sidebar .review-summary .location-rating {
        display: inline;
    }

    .location-sidebar .field .label, .location-sidebar span.location-text {
        font-weight: 500;
        padding-bottom: 10px;
    }
    span.location-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #212529;
    }

    .location-sidebar .review-summary .location-rating .filled {
        font-size: 20px;
        color: #ffc738;
    }
    .location-sidebar div.field {
        margin-bottom: 25px;
    }
    .location-sidebar .field .label, .location-sidebar span.location-text {
        font-weight: 500;
        padding-bottom: 10px;
    }
    
    .location-sidebar .label, .location-sidebar .field>span {
        display: block;
        font-style: normal;
        font-weight: 400;
        color: #212529;
    }
    .location-sidebar .field .label, .location-sidebar span.location-text {
        font-weight: 500;
        padding-bottom: 10px;
    }
    .location-sidebar .lp-office-hrs span, .location-sidebar .lp-supt-hrs span {
        display: inline-block;
    }
    .location-sidebar .label, .location-sidebar .field>span {
        display: block;
        font-style: normal;
        font-weight: 400;
        color: #212529;
    }
   
    .location-sidebar .label.tz {
        color: #707c8d;
        font-style: italic;
        font-weight: 400;
        float: right;
    }
    table {
        border-collapse: collapse;
        width: 500px;
    }
   
    .collapse:not(.show) {
        display: none;
    }
    .location-sidebar .field .label, .location-sidebar span.location-text {
        font-weight: 500;
        padding-bottom: 10px;
    }
    .location-sidebar .lp-office-hrs span, .location-sidebar .lp-supt-hrs span {
        display: inline-block;
    }

    .location-sidebar .lp-supt-hrs {
        position: relative;
    }

    
    .location-sidebar .field .label, .location-sidebar span.location-text {
        font-weight: 500;
        padding-bottom: 10px;
    }
    
    .location-sidebar .label, .location-sidebar .field>span {
        display: block;
        font-style: normal;
        font-weight: 400;
        color: #212529;
    }

    .location-sidebar .lp-supt-hrs a[aria-expanded=true]+span.label.tz {
        display: block;
    }
    .location-sidebar .lp-supt-hrs span.label.tz {
        top: 0;
        right: 0;
        position: absolute;
    }

    #location-content .bottom-cta, #location-content section {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    #location-content section, #location-sub-content {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    section.bottom-cta {
        text-align: center;
        display: block;
        padding: 0 0 60px;
    }
    .bottom-cta .row.blog-col {
        display: block !important;
        padding-right: 0 !important;
    }
    .cta-container {
        text-align: center;
        background: #eef8fc;
        padding: 60px 60px 0;
        border: 1px solid #c9e8f7;
        box-shadow: 4px 4px #c9e8f7;
    }
    #location-content .cta-container h3, #location-sub .cta-container h3 {
        margin-bottom: 10px;
        max-width: 700px;
    }
    
    #location-content .cta-container h3, #location-sub .cta-container h3 {
        color: #212529;
    }
    .cta-container>p:nth-of-type(1) {
        margin-top: 5px;
        padding-bottom: 15px;
    }
    .cta-container p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        width: 55%;
        margin: 0 auto 20px;
        text-align: center;
        display: block;
    }
    p:empty {
        display: none !important;
    }
    p {
        padding-bottom: 1em;
    }

    .bottom-cta-btns {
        display: block;
        margin: 25px 0 40px;
    }

    .cta-container a.location-cta-book-btn, .cta-container a.find-counselor-cta-book-btn {
        padding: 15px 30px !important;
        background: #ffc738 !important;
        color: #212529 !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        margin-right: 10px !important;
        text-decoration: none !important;
        border-radius: 100px !important;
        border: none;
    }
    
    a.cta-call-session {
        border: 1px solid #274588;
        padding: 15px 30px;
        border-radius: 6px;
        color: #274588;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none !important;
    }    
    .cta-container a.location-cta-book-btn, .cta-container a.find-counselor-cta-book-btn {
        display: initial;
    }
    .cta-container a {
        text-decoration: underline;
    }

    .cta-container>p:last-of-type {
        margin-top: -5px;
        text-align: center;
    }
    .cta-container p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        width: 55%;
        margin: 0 auto 20px;
        text-align: center;
        display: block;
    }
    .cta-container a.cta-call-session {
        border: 1px solid #14467c;
        border-radius: 25100px;
        color: #14467c;
    }

    #location-content .bottom-cta, #location-content section {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    #location-content section, #location-sub-content {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    .pricing-insurance h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #212529;
    }

    .pricing-insurance p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212529;
    }
    ul.insurance-listing {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
    }
    .insurance-listing li {
        background: #f7f9fb;
        border-radius: 10px;
        padding: 12px 26px;
        margin-right: 10px;
        margin-top: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: .005em;
        color: #666;
        height: 50px;
    }
    .pricing-insurance a {
        color: #0d85f4;
        text-decoration: none;
    }

    .section.language-sec {
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 64px !important;
    }
    .spoken-language h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #212529;
    }
    ul.language-listing {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
    }
    .language-listing li {
        background: #f7f9fb;
        border-radius: 10px;
        padding: 12px 26px;
        margin-right: 10px;
        margin-top: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: .005em;
        color: #666;
        height: 50px;
    }
    .review-section .review-main {
        display: flex;
        justify-content: space-between;
    }
    .review-section .overall-reviews {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 5px;
        display: inherit;
    }
    .review-section .see-text a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #0d85f4 !important;
        text-decoration: none !important;
    }
    .review-section .why-clients {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #212529;
    }
    .review-section .why-clients {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #212529;
    }
    .review-section .why-clients a {
        color: #0d85f4;
        text-decoration: none;
    }
    .review-section .reviews_wrapper {
        position: relative;
    }
    
    .slick-slider {
        margin-bottom: 20px;
    }
    .slick-slider {
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .review-section .reviews_wrapper:before {
        position: absolute;
        content: "";
        width: 5%;
        height: 100%;
        right: -2px;
        top: 0;
        z-index: 1;
        background: linear-gradient(270deg,#fff 0%,rgba(255,255,255,0) 100%);
    }
    .slick-slider .slick-list, .slick-slider .slick-track {
        -webkit-transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        -o-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
    .slick-list {
        margin-bottom: 25px !important;
    }
    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    .slick-list, .slick-slider, .slick-track {
        position: relative;
        display: block;
    }

    .slick-slider .slick-list, .slick-slider .slick-track {
        -webkit-transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        -o-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
    
    .slick-track {
        top: 0;
        left: 0;
    }
    .slick-track:after, .slick-track:before {
        display: table;
        content: '';
    }
    .review-section .rating {
        font-size: 20px;
        color: #ffc738;
    }

    span.filled {
        font-size: 20px;
        color: #ffc738;
    }

    .review-section .reviews {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 5px;
        color: #212529;
        min-height: 100px;
    }
    .review-section span.review-full-text {
        color: #0d85f4 !important;
    }
    
    .review-section .review-full-text {
        cursor: pointer;
    }
    .review-section .reviewer {
        color: #707c8d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        margin-top: 5px;
    }
    .slick-track:after {
        clear: both;
    }
    
    .slick-track:after, .slick-track:before {
        display: table;
        content: '';
    }

    #location-content section, #location-sub-content {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    
    .grey-border-bottom {
        border-bottom: 1px solid #e7e7e7;
    }
    .services-wrap {
        margin-bottom: 50px;
        width: 100%;
    }
    #location-content #lp-location-info .heading, #location-content #lp-services .heading, #main-content .heading, .location-subpage-heading .heading {
        font-size: 24px;
        font-weight: 500 !important;
        color: #212529;
        padding-bottom: 20px;
    }
    .services-wrap ol {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -50px;
        margin-right: -15px;
    }
    .services-wrap .tile {
        list-style: none;
        flex: 0 0 calc(20% - 30px);
        align-self: stretch;
        padding: 20px;
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        text-align: center;
        margin: 10px 15px;
        min-height: 90px;
    }
    .services-wrap .tile span {
        height: 100%;
        font-size: 16px;
        display: inline-flex;
        align-items: center;
    }
    section.language-sec {
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 64px !important;
    }

    section.review-section {
    padding: 0 0 60px;
}


    #location-content #main-content {
        clear: both;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }

    #location-content section, #location-sub-content {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    
    .grey-border-bottom {
        border-bottom: 1px solid #e7e7e7;
    }

    #location-content #lp-location-info .heading, #location-content #lp-services .heading, #main-content .heading, .location-subpage-heading .heading {
        font-size: 24px;
        font-weight: 500 !important;
        color: #212529;
        padding-bottom: 20px;
    }
    article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
        display: block;
    }
    .loc-content h2 {
        font-size: 24px;
        font-weight: 500 !important;
        margin-top: 24px;
        color: black;
    }
    #location-content .entry-content span {
        line-height: 1.5;
    }
    #location-content .entry-content p, #location-content .entry-content li {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #212529;
    }
    
    .loc-content p {
        padding-bottom: 1.4em;
    }
    #location-content #main-content {
        clear: both;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }
    #location-content section, #location-sub-content {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    #location-content #lp-location-info .heading, #location-content #lp-services .heading, #main-content .heading, .location-subpage-heading .heading {
        font-size: 24px;
        font-weight: 500 !important;
        color: #212529;
        padding-bottom: 20px;
    }
    #more-about-wrap .heading {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        padding: 0;
    }

    .more-about-location .more-sub-section .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        letter-spacing: .06em;
        text-transform: uppercase;
        color: #707c8d;
        display: inline-block;
        margin-top: 25px;
    }
    .more-about-location .more-sub-section li {
        list-style: none;
    }

    .more-about-location a {
        color: #0d85f4;
    }

    .more-about-location .more-sub-section li {
        list-style: none;
    }
    a{
        text-decoration: none;
    }

    section.bottom-cta {
        text-align: center;
        display: block;
        padding: 0 0 60px;
    }

    .bottom-cta .row.blog-col {
        display: block !important;
        padding-right: 0 !important;
    }

    .cta-container {
        text-align: center;
        background: #eef8fc;
        padding: 60px 60px 0;
        border: 1px solid #c9e8f7;
        box-shadow: 4px 4px #c9e8f7;
    }
    #location-content .cta-container h3, #location-sub .cta-container h3 {
        margin-bottom: 10px;
        max-width: 700px;
    }
    #location-content .cta-container h3, #location-sub .cta-container h3 {
        color: #212529;
    }

    .cta-container>p:nth-of-type(1) {
        margin-top: 5px;
        padding-bottom: 15px;
    }
    
    .cta-container p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        width: 55%;
        margin: 0 auto 20px;
        text-align: center;
        display: block;
    }
    .bottom-cta-btns {
        display: block;
        margin: 25px 0 40px;
    }
    .cta-container>p:last-of-type {
        margin-top: -5px;
        text-align: center;
    }
    .cta-container p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        width: 55%;
        margin: 0 auto 20px;
        text-align: center;
        display: block;
    }

    .page-template-location .inthenews_section {
        margin-top: 64px;
    }
    .inthenews_section {
        width: 100%;
        display: inline-block;
    }
    .inthenews-head {
        width: 100%;
        display: inline-block;
        font-size: 24px;
        line-height: 30px;
        font-weight: 500 !important;
        color: #212529;
        margin-bottom: 8px;
    }

    .inthenews_wrapper.slick-initialized {
        visibility: visible;
    }
    
    .slick-slider {
        margin-bottom: 20px;
    }
    .slick-list {
        margin-bottom: 25px !important;
    }
    
    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .inthenews_section .inthenews_wrapper .slick-list:before {
        position: absolute;
        content: "";
        width: 5%;
        height: 100%;
        right: -2px;
        top: 0;
        z-index: 1;
        background: linear-gradient(270deg,#fff 0%,rgba(255,255,255,0) 100%);
    }
    .inthenews_section .slick-track {
        padding-bottom: 8px;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    
   
    .news-wrapper {
        border-radius: 1px;
        border: 1px solid #e7e7e7;
        padding: 24px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column !important;
        margin-right: 30px;
        width: 100%;
    }

    .news-heading {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
    }
   
    .news-heading {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
    }
    .news-row {
        width: 100%;
        display: inline-block;
        margin-top: 15px;
    }

    .news-sub-head {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
    }
    
    .news-sub-head {
        width: 100%;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #212529;
    }

    .news-date {
        width: 100%;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #707c8d;
    }

    @media only screen and (min-width: 1200px) {
        .container {
            max-width: 1200px !important;
        }
    }

    @media screen and (min-width: 768px) {
        #lp-location-info #lp-map {
            width: 100%;
            height: auto;
            min-height: 500px;
        }

        iframe {
            min-height: 500px;
        }
    }

}