.contact-us {
    section .my-3 form .row button.btn-submit{
        background-color: #f92!important;
        margin: auto;
        color: #fff !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        position: relative;
        display: inline-block;
        text-decoration: none;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 40px;
        padding: 5px 30px;
        border-radius: 3px;
        border: 2px solid #ffebcd;
        cursor: pointer;
        width: 18%;
    }
    
    .text-center {
        text-align: center !important;
    }

    .fa-phone::before {
        content: "\f095";
    }

   .col-md-12 i.fa-phone, .fa-location-arrow, .fa-handshake-o{
        font-size: 150px !important;
        background: -webkit-linear-gradient(#9ce, #69c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .text-center .info #info{
        font-size: 20px;
        line-height: 1.5;
        font-weight: 700;
        color: #555;
    }
    .my-3 {
        margin-bottom: 1rem!important;
    }
   input, select, textarea {
        color: #555;
        border-radius: 3px;
        background-color: #fff;
        border: none;
        width: 100%;
        line-height: 24px;
        font-size: 20px;
        -webkit-transition: box-shadow .25s;
        transition: box-shadow .25s ease 0s;
        padding: 15px;
        outline: 0;
        margin-top: 25px;
    }
    @media only screen and (max-width: 768px) {
        section .my-3 form .row button.btn-submit{
            width: 28%;
        }

    }

    @media only screen and (max-width: 480px) {
        section .my-3 form .row button.btn-submit{
            width: 58%;
        }
    }
    
}